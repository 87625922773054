@font-face {
    font-family: 'Satoshi';
    font-weight: 900;
    src: local('Satoshi'), url(./fonts/Satoshi-Black.ttf) format('truetype');
}
  
@font-face {
    font-family: 'Satoshi';
    font-weight: 700;
    src: local('Satoshi'), url(./fonts/Satoshi-Bold.ttf) format('truetype');
}
  
@font-face {
    font-family: 'Satoshi';
    font-weight: 500;
    src: local('Satoshi'), url(./fonts/Satoshi-Medium.ttf) format('truetype');
}
  
@font-face {
    font-family: 'Satoshi';
    font-weight: 400;
    src: local('Satoshi'), url(./fonts/Satoshi-Regular.ttf) format('truetype');
}